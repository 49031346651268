import { Box, Divider, Drawer } from '@mui/material';
import React, { useState } from 'react';
import CandidateActivityComponent from './CandidateActivityComponent';
import CandidateEvaluationComponent from './CandidateEvaluationComponent';
import CandidateProfileComponent from './CandidateProfileComponent';
import CandidateProfileHeader from './CandidateProfileHeader';
import styled from 'styled-components';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';

interface CandidateProfileDrawerProps {
    isOpen: boolean;
    onClose: () => void;
}

const CustomTabs = styled(Tabs)`
  border-bottom: 2px solid #E0E0E0;
  margin-bottom: 16px;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #E0E0E0; /* Border at the bottom of the tab list */
  background: #FFF;
`;

const CustomTab = styled(Tab) <{ isActive: boolean }>`
  padding: 10px 0px 12px;  /* Adjust padding for proper spacing */
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${props => (props.isActive ? '#2979FF' : '#757575')};
  border-bottom: ${props => (props.isActive ? '3px solid #2979FF' : '3px solid transparent')}; /* Border for active tab */
  transition: color 0.3s, border-bottom 0.3s;
  margin: 0 16px;
  
  &:focus {
    outline: none;
  }

  &:hover {
    color: #2979FF;
  }
`;

const CandidateProfileDrawerComponent: React.FC<CandidateProfileDrawerProps> = ({ isOpen, onClose }) => {
    const [activeTab, setActiveTab] = useState('Evaluation');

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <Box width="1000px" padding="16px">
                <CandidateProfileHeader />

                <CustomTabs
                    selectedIndex={['Evaluation', 'Profile', 'Activity'].indexOf(activeTab)}
                    onSelect={index => setActiveTab(['Evaluation', 'Profile', 'Activity'][index])}
                >
                    <CustomTabList>
                        <CustomTab isActive={activeTab === 'Evaluation'}>Evaluation Status</CustomTab>
                        <CustomTab isActive={activeTab === 'Profile'}>Profile</CustomTab>
                        {/* <CustomTab disabled={true} isActive={activeTab === 'Activity'}>Activity</CustomTab> */}
                    </CustomTabList>

                    <TabPanel>
                        <CandidateEvaluationComponent />
                    </TabPanel>
                    <TabPanel>
                        <CandidateProfileComponent />
                    </TabPanel>
                    <TabPanel>
                        <CandidateActivityComponent />
                    </TabPanel>
                </CustomTabs>
            </Box>
        </Drawer>
    );
};

export default CandidateProfileDrawerComponent;