import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';
import CandidateProfileDrawerComponent from '../components/CandidateProfileDrawerComponent';
import HeaderTabs from '../components/HeaderTabs';
import JobDetails from '../components/JobDetails';
import JobNavigation from '../components/JobNavigation';
import { JobPipelineProvider, useJobPipeline } from '../contexts/JobPipelineContext';
import Section from './Section';

const PipelineWrapper = styled.div`
    background-color: #F8FAFF;
    min-height: calc(100vh - 57px); 
`;

const BodyWrapper = styled.div`
    padding: 16px;
`;

const JobPipelineComponent: React.FC = () => {
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);

    const {
        currentJob,
        activeTab,
        handleTabChange,
        activeCandidateProfile,
        handleMenuAction,
        talentPoolCandidates,
        coachClientEvaluationsCandidates,
        selectedCandidates,
        handleOnDragEnd,
        handleActiveCandidateProfile,
    } = useJobPipeline();

    return (
        <>
            <PipelineWrapper>
                <HeaderTabs expertId={expertId} onTabChange={handleTabChange} />
                <BodyWrapper>
                    <JobNavigation />
                    <JobDetails />
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Section
                                sectionId="talent_pool"
                                title="Talent Pool"
                                candidates={talentPoolCandidates}
                                onMenuAction={handleMenuAction}
                            />
                            <Section
                                sectionId="interview_request"
                                title="Coach & Client Evaluations"
                                candidates={coachClientEvaluationsCandidates}
                                onMenuAction={handleMenuAction}
                            />
                            <Section
                                sectionId="finalized_candidate"
                                title="Selected Candidates"
                                candidates={selectedCandidates}
                                onMenuAction={handleMenuAction}
                            />
                        </div>
                    </DragDropContext>
                </BodyWrapper>
            </PipelineWrapper>
            <CandidateProfileDrawerComponent isOpen={!!activeCandidateProfile} onClose={() => handleActiveCandidateProfile()} />
        </>
    );
};

interface IJobPipelineWrapper {
    expertId: string;
    jobId: string
}

export default function JobPipelineWrapper({ expertId, jobId }: IJobPipelineWrapper) {
    return (
        <JobPipelineProvider expertId={expertId} jobId={jobId}>
            <JobPipelineComponent />
        </JobPipelineProvider>
    );
}