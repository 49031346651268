/** @jsxImportSource @emotion/react */
import { Box, Divider, Step, StepButton, StepContent, Stepper, Typography, CircularProgress } from '@mui/material';
import { CircleDot } from "@styled-icons/fa-solid/CircleDot";
import { AwsUploader } from 'components/Uploader/AwsUploader';
import { useJobPipeline } from 'containers/JobPipeline/contexts/JobPipelineContext';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCandidateEvaluationStatus, getResumeParsingStatus } from 'services/jobPipeline';
import { jobsService } from 'services/jobs';
import { RootState } from 'store';
import styled from 'styled-components';
import { CheckCircleOutline } from 'styled-icons/material';
import { ClientEvaluation, EvaluationData } from 'types/jobPipeline';
import AIEvaluationComponent from './AIEvaluationComponent';
import AIResumeEvaluationComponent from './AIReesumeEvaluationComponent';
import ClientEvaluationComponent from './ClientEvaluationComponent';
import EvaluationSelectionComponent from './EvaluationSelectionComponent';
import IntentEvaluationComponent from './IntentEvaluation';

const StyledCheckCircleOutline = styled(CheckCircleOutline)`
  width: 24px;
  height: 24px;
  color: #4caf50;
`;

const StyledRadioButtonUnchecked = styled(CircleDot)`
  width: 24px;
  height: 24px;
  color: #757575;
`;

const POLLING_INTERVAL = 60000;

const CandidateEvaluationComponent: React.FC = () => {
    const { currentJob, activeCandidateProfile } = useJobPipeline();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState<Set<number>>(new Set());
    const [evaluationData, setEvaluationData] = useState<EvaluationData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [noData, setNoData] = useState<boolean>(false);
    const [parsingTimer, setParsingTimer] = useState<number>(POLLING_INTERVAL / 1000);
    const [isPolling, setIsPolling] = useState<boolean>(false); // New state to track polling status
    const [hasUploaded, setHasUploaded] = useState<boolean>(false); // Tracks if a file has been uploaded
    const candidateId = activeCandidateProfile?.candidateId || '';
    const trackId = currentJob?.trackId || '';
    const attachmentsDir = `${candidateId}/Resumes/${trackId}`;

    const fetchEvaluationStatus = useCallback(async () => {
        if (candidateId && currentJob?.jobId && expertId) {
            try {
                setLoading(true);
                setNoData(false);
                const data = await getCandidateEvaluationStatus(expertId, currentJob.jobId, candidateId);

                if (data) {
                    setEvaluationData(data);

                    if (data.aiResumeEvaluation || data.resumeUrl) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 0]));
                    }

                    if (data.aiResumeEvaluation?.careerProgressionDetails) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 1]));
                    }

                    if (data.intentEvaluation?.completedOn) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 2]));
                    }

                    if (data.coachEvaluation?.completedOn) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 3]));
                    }

                    if (data.clientEvaluation && (data.clientEvaluation as ClientEvaluation[]).every(
                        ev => ev.completedOn && ev.feedback.trim() !== ''
                    )) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 4]));
                    }

                    if (data.selectionStatus?.completedOn) {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 5]));
                    }

                    // Poll only if resumeUrl is missing and a file was uploaded
                    if (!data.resumeUrl && hasUploaded) {
                        setIsPolling(true); // Start polling
                    }
                } else {
                    setNoData(true);
                }
            } catch (error) {
                console.error("Failed to fetch candidate evaluation status:", error);
                setNoData(true);
            } finally {
                setLoading(false);
            }
        }
    }, [expertId, currentJob, candidateId, hasUploaded]);

    useEffect(() => {
        fetchEvaluationStatus();
    }, [fetchEvaluationStatus]);

    const handleEvaluationSuccess = () => {
        setCompleted(prevCompleted => new Set([...prevCompleted, 2]));
        setActiveStep(-1);
        fetchEvaluationStatus();
    };

    const pollResumeParsingStatus = useCallback(async () => {
        if (candidateId && expertId) {
            try {
                const response: any = await getResumeParsingStatus(expertId, candidateId);
                console.log(response);
                if (response?.resumeParsingCompleted) {
                    await fetchEvaluationStatus(); 
                    setIsPolling(false); 
                } else {
                    setParsingTimer(POLLING_INTERVAL / 1000);
                }
            } catch (error) {
                console.error('Error fetching resume parsing status:', error);
            }
        }
    }, [expertId, candidateId, fetchEvaluationStatus]);

    useEffect(() => {
        if (isPolling) {
            const interval = setInterval(() => {
                setParsingTimer(prevTimer => (prevTimer > 0 ? prevTimer - 1 : 0));
                if (parsingTimer === 0) {
                    pollResumeParsingStatus();
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [parsingTimer, pollResumeParsingStatus, isPolling]);

    const handleImageUpload = async (fileUrl: string) => {
        try {
            console.log(`File uploaded successfully: ${fileUrl}`);
            await jobsService.updateCandidateDetails(expertId, candidateId, fileUrl);
            setCompleted(prevCompleted => new Set([...prevCompleted, 0]));
            setActiveStep(1);
            setHasUploaded(true); // File upload initiated
            setIsPolling(true); // Start polling once the file is uploaded
            await fetchEvaluationStatus();
        } catch (error) {
            console.error('Failed to update candidate details:', error);
        }
    };

    const isStepComplete = (step: number) => completed.has(step);

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (noData || !evaluationData) {
        return <Typography>No data available</Typography>;
    }

    const steps = [
        {
            label: evaluationData?.aiResumeEvaluation?.resumeUrl ? 'AI Resume Evaluation' : 'Upload Resume',
            description: evaluationData?.aiResumeEvaluation?.resumeUrl ? (
                <AIResumeEvaluationComponent aiResumeEvaluation={evaluationData.aiResumeEvaluation} />
            ) : isPolling ? ( // Show inline spinner when polling after upload
                <Box display="flex" alignItems="center">
                    <CircularProgress size={20} />
                    <Typography sx={{ ml: 2 }}>Parsing in progress. Next check in: {parsingTimer}s</Typography>
                </Box>
            ) : (
                <Box display="flex" alignItems="center">
                    <AwsUploader
                        onUpload={handleImageUpload}
                        directory={attachmentsDir}
                        expertId={expertId}
                        allowAllUpload={true}
                    />
                </Box>
            ),
        },
        {
            label: 'AI Evaluation',
            description: evaluationData.aiEvaluation ? <AIEvaluationComponent aiEvaluation={evaluationData.aiEvaluation} /> : <></>,
        },
        {
            label: 'Intent Evaluation',
            description: (
                <IntentEvaluationComponent
                    expertId={expertId}
                    jobId={currentJob?.jobId || ''}
                    candidateId={activeCandidateProfile?.candidateId || ''}
                    onSubmitSuccess={handleEvaluationSuccess}
                    candidateIntentData={evaluationData.intentEvaluation}
                />
            ),
        },
        {
            label: 'Coach Evaluation',
            description: (
                <Box>
                    {evaluationData.coachEvaluation ? (
                        <Box>
                            <Box display="flex" alignItems="center" mb={1}>
                                {evaluationData.coachEvaluation.profileImgUrl ? (
                                    <img
                                        src={evaluationData.coachEvaluation.profileImgUrl}
                                        alt={evaluationData.coachEvaluation.expertName}
                                        style={{ borderRadius: '50%', marginRight: '10px', width: '40px', height: '40px' }}
                                    />
                                ) : (
                                    <Box
                                        sx={{
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            backgroundColor: '#3f51b5',
                                            color: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginRight: '10px',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {evaluationData.coachEvaluation.expertName
                                            ?.split(' ')
                                            .map(n => n[0])
                                            .join('')}
                                    </Box>
                                )}
                                <Box>
                                    <Typography variant="body1">
                                        {evaluationData.coachEvaluation.expertName}
                                        {evaluationData.coachEvaluation.coachEvalScore && (
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    padding: '2px 8px',
                                                    borderRadius: '8px',
                                                    backgroundColor: '#f0e68c',
                                                    color: '#333',
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    ml: 1,
                                                }}
                                            >
                                                Coach Eval Score: {evaluationData.coachEvaluation.coachEvalScore.toFixed(1)}
                                            </Box>
                                        )}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {moment(evaluationData.coachEvaluation.completedOn).format('MMMM D, YYYY')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Typography variant="body2" mb={2}>
                                {evaluationData.coachEvaluation.feedback}
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                        </Box>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            The client evaluation is currently under process. Please check back later.
                        </Typography>
                    )}
                </Box>
            ),
        },
        {
            label: 'Client Evaluation',
            description: <ClientEvaluationComponent
                expertId={expertId}
                jobId={currentJob?.jobId || ''}
                candidateId={activeCandidateProfile?.candidateId || ''}
                evaluationData={evaluationData}
                onSubmitSuccess={handleEvaluationSuccess} />,
        },
        {
            label: 'Selection',
            description: (
                <EvaluationSelectionComponent
                    expertId={expertId}
                    jobId={currentJob?.jobId || ''}
                    candidateId={activeCandidateProfile?.candidateId || ''}
                    selectionStatus={evaluationData?.selectionStatus}
                    onSubmitSuccess={() => {
                        setCompleted(prevCompleted => new Set([...prevCompleted, 5]));
                        setActiveStep(-1);
                        fetchEvaluationStatus();
                    }}
                />
            ),
        },
    ];

    return (
        <Box>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepButton
                            onClick={() => setActiveStep(index)}
                            icon={isStepComplete(index) ? <StyledCheckCircleOutline /> : <StyledRadioButtonUnchecked />}
                        >
                            <Typography variant="subtitle1">{step.label}</Typography>
                        </StepButton>
                        <StepContent>
                            {step.description}
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default CandidateEvaluationComponent;