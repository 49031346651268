import { Box, Divider, IconButton, Link, Typography, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCandidateDetails } from 'services/jobPipeline';
import { RootState } from 'store';
import styled from 'styled-components';
import { Linkedin } from 'styled-icons/boxicons-logos';
import { Profile } from 'styled-icons/remix-fill';
import { CandidateDetails } from 'types/jobPipeline';
import { useJobPipeline } from '../contexts/JobPipelineContext';
import CandidateResumeComponent from './CandidateResumeComponent';

const ProfileLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #325CD6;
  text-decoration: underline;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.25px;
`;

const LinkedInIcon = styled(Linkedin)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: #325CD6;
`;

const LabelText = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #6D6D6D;
`;

const InfoText = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #325CD6;
  text-decoration: underline;
`;

const ExperienceTag = styled.span<{ background: string }>`
  background-color: ${(props) => props.background};
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 600;
`;

const CandidateProfileComponent: React.FC = () => {
    const { activeCandidateProfile } = useJobPipeline();
    const expertId = useSelector((state: RootState) => state.auth.user.expertId);

    const [candidateDetails, setCandidateDetails] = useState<CandidateDetails | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [noData, setNoData] = useState<boolean>(false);
    const [openResumeModal, setOpenResumeModal] = useState<boolean>(false);
    const [isResumeLoading, setIsResumeLoading] = useState<boolean>(false);

    useEffect(() => {
        if (activeCandidateProfile?.candidateId) {
            const fetchDetails = async () => {
                if (expertId && activeCandidateProfile.candidateId) {
                    try {
                        setLoading(true);
                        setNoData(false);
                        const details = await getCandidateDetails(expertId, activeCandidateProfile.candidateId);
                        if (details) {
                            setCandidateDetails(details);
                        } else {
                            setNoData(true);
                        }
                    } catch (error) {
                        console.error('Failed to fetch candidate details:', error);
                        setNoData(true);
                    } finally {
                        setLoading(false);
                    }
                }
            };
            fetchDetails();
        } else {
            setNoData(true);
        }
    }, [expertId, activeCandidateProfile]);

    const handleResumeClick = async () => {
        setIsResumeLoading(true);
        try {
            const details = await getCandidateDetails(expertId, activeCandidateProfile?.candidateId || '');
            setCandidateDetails(details);
            setOpenResumeModal(true);
        } catch (error) {
            console.error('Failed to fetch candidate resume:', error);
        } finally {
            setIsResumeLoading(false);
        }
    };

    const handleModalClose = () => {
        setOpenResumeModal(false);
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (noData || !candidateDetails) {
        return <Typography>No data available</Typography>;
    }

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                    Basic Information
                </Typography>
                <Box display="flex" alignItems="center" onClick={handleResumeClick} style={{ cursor: 'pointer' }}>
                    {isResumeLoading ? (
                        <CircularProgress size={20} color="primary" />
                    ) : (
                        <>
                            <IconButton size="small">
                                <Profile height={18} width={18} style={{ color: '#325CD6' }} />
                            </IconButton>
                            <Link href="#" color="#2979FF" underline="hover" fontWeight="500">
                                View AI Generated Resume
                            </Link>
                        </>
                    )}
                </Box>
            </Box>

            <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2} mb={2}>
                <Box>
                    <LabelText fontSize={10}>Email:</LabelText>
                    <ProfileLink href={`mailto:${candidateDetails.email}`}>
                        {candidateDetails.email}
                    </ProfileLink>
                    <LabelText fontSize={10} mt={2}>
                        Location:
                    </LabelText>
                    <Typography variant="body1" fontSize={14}>
                        {candidateDetails.location || candidateDetails.country}
                    </Typography>
                    <LabelText fontSize={10} mt={2}>
                        Experience:
                    </LabelText>
                    <Typography variant="body1" fontSize={14}>
                        {candidateDetails.experienceYears} years
                    </Typography>
                </Box>

                <Box textAlign="left">
                    <LabelText fontSize={10}>Phone:</LabelText>
                    <InfoText fontSize={14} color="#325CD6">
                        N/A
                    </InfoText>
                    <LabelText fontSize={10} mt={2}>
                        Time Zone:
                    </LabelText>
                    <Typography variant="body1" fontSize={14}>
                        {candidateDetails.timezone}
                    </Typography>
                    <LabelText fontSize={10} mt={2}>
                        Current Salary:
                    </LabelText>
                    <Typography variant="body1" fontSize={14}>
                        ${candidateDetails.expectedSalary}
                    </Typography>
                </Box>

                <Box textAlign="left">
                    <LabelText fontSize={10}>Profile:</LabelText>
                    <ProfileLink href={candidateDetails.linkedInUrl}>
                        <LinkedInIcon />
                        LinkedIn
                    </ProfileLink>
                    <LabelText fontSize={10} mt={2}>
                        Employment Type:
                    </LabelText>
                    <Typography variant="body1" fontSize={14}>
                        {candidateDetails.jobType}
                    </Typography>
                </Box>
            </Box>

            <Divider />

            <Box display="flex" justifyContent="space-between" mt={2}>
                <Box width="48%">
                    <Typography variant="subtitle1" fontWeight={"bold"} mb={2}>
                        Experience
                    </Typography>
                    {candidateDetails.candidateExperience.map((exp, index) => (
                        <Box key={index} mb={2}>
                            <Typography fontSize={14} variant="body1">
                                {exp.title}{' '}
                                <ExperienceTag background="#4caf50">
                                    {exp.tier.replace('_', ' ').toUpperCase()}
                                </ExperienceTag>
                            </Typography>
                            <Typography fontSize={10} variant="body2" color="textSecondary">
                                {exp.company} | {exp.duration}
                            </Typography>
                        </Box>
                    ))}
                </Box>

                <Divider orientation="vertical" flexItem />

                <Box width="48%">
                    <Typography variant="subtitle1" fontWeight={"bold"} mb={2}>
                        Education
                    </Typography>
                    {candidateDetails.educationDetails.map((edu, index) => (
                        <Box key={index} mb={2}>
                            <Typography fontSize={14} variant="body1">
                                {edu.degree}{' '}
                                <ExperienceTag background="#81c784">
                                    University Score: {edu.universityScore}
                                </ExperienceTag>
                            </Typography>
                            <Typography fontSize={10} variant="body2" color="textSecondary">
                                {edu.university} | {edu.passingYear}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>

            <CandidateResumeComponent
                open={openResumeModal}
                handleClose={handleModalClose}
                candidateId={candidateDetails.candidateId}
                expertId={expertId}
            />
        </Box>
    );
};

export default CandidateProfileComponent;