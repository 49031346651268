import React, { useMemo, useState } from 'react';
import { Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Plus } from 'styled-icons/bootstrap';
import { Candidate } from 'types/jobPipeline';
import JobCard from '../components/JobCard';
import { CreateCandidateWorkFlow, CreateCandidateWorkflowSteps } from 'pages/B2B/Jobs/CreateCandidateWorkFlow';
import { useJobPipeline } from '../contexts/JobPipelineContext';
import { STATUS_TYPE } from 'types/Jobs';
import useJobDispatcher from 'containers/HiringManager/Jobs.util';

const SectionContainer = styled.div<{ isDraggingOver: boolean; isDropDisabled: boolean }>`
  margin-bottom: 16px;
  padding: 24px;
  margin: 10px;
  background-color: ${(props) =>
    props.isDraggingOver ? (props.isDropDisabled ? '#f8d7da' : '#e0e6f8') : '#F0F3FA'};
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
`;

const SectionTitle = styled.h5`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
`;

const AddCandidateButton = styled(Button)`
  align-items: center;
  border: 1px solid #325cd6;
  font-weight: bold;
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

interface SectionProps {
  sectionId: string;
  title: string;
  candidates: Candidate[];
  onMenuAction: (action: string, id: string) => void;
}

const Section: React.FC<SectionProps> = ({ sectionId, title, candidates, onMenuAction }) => {
  const { currentJob, fetchJobById } = useJobPipeline();
  const { updateCandidateInfoDispatcher } = useJobDispatcher();

  const [favoriteStatus, setFavoriteStatus] = useState<Map<string, boolean>>(new Map());
  const [showCreateCandidate, setShowCreateCandidate] = useState<boolean>(false);
  const [startWorkFlow, setStartWorkFlow] = useState<CreateCandidateWorkflowSteps | undefined>();

  const sortedCandidates = useMemo(() => {
    const adjustedCandidates = candidates
      .filter(candidate => candidate && candidate.candidateId)
      .map(candidate => ({
        ...candidate,
        favorite: favoriteStatus.get(candidate.candidateId) ?? candidate.favorite,
      }));

    return adjustedCandidates.sort((a, b) => Number(b.favorite) - Number(a.favorite));
  }, [candidates, favoriteStatus]);

  const handleFavoriteToggle = (id: string, favorite: boolean) => {
    setFavoriteStatus(prevStatus => {
      const newStatus = new Map(prevStatus);
      newStatus.set(id, favorite);
      return newStatus;
    });
  };

  const updateCandidateResume = async (candidateState: STATUS_TYPE, candidateId: string, url: string) => {
    if (currentJob) {
      await updateCandidateInfoDispatcher(candidateState, candidateId, { resumeUrl: url });
      await fetchJobById(currentJob.jobId);
    }
  };

  const handleAddCandidate = () => {
    setStartWorkFlow(CreateCandidateWorkflowSteps.CREATE_CANDIDATE);
    setShowCreateCandidate(true);
  };

  const handleMeetingBooked = () => { };

  return (
    <Droppable droppableId={sectionId}>
      {(provided, snapshot) => (
        <SectionContainer
          ref={provided.innerRef}
          isDraggingOver={snapshot.isDraggingOver}
          isDropDisabled={false}
          {...provided.droppableProps}
        >
          <SectionTitle>{title}</SectionTitle>
          {sectionId === 'talent_pool' && (
            <AddCandidateButton className="my-2" variant={"outline-primary"} onClick={handleAddCandidate}>
              <Plus size={24} /> Add a Candidate
            </AddCandidateButton>
          )}
          {sortedCandidates.map((candidate, index) => (
            <Draggable
              key={candidate.candidateId}
              draggableId={candidate.candidateId}
              index={index}
            >
              {(provided) => (
                <JobCard
                  ref={provided.innerRef}
                  candidate={candidate}
                  index={index}
                  onFavoriteToggle={handleFavoriteToggle}
                  onMenuAction={onMenuAction}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                />
              )}
            </Draggable>
          ))}
          {provided.placeholder}
          {showCreateCandidate && currentJob?.jobId && currentJob?.trackId && (
            <CreateCandidateWorkFlow
              setShowCreateCandidate={setShowCreateCandidate}
              jobId={currentJob.jobId}
              trackId={currentJob.trackId}
              startWorkFlow={startWorkFlow ?? CreateCandidateWorkflowSteps.SELECT_PRODUCT}
              handleMeetingBooked={handleMeetingBooked}
              updateCandidateResume={updateCandidateResume}
            />
          )}
        </SectionContainer>
      )}
    </Droppable>
  );
};

export default Section;