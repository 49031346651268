import { Box, CircularProgress, Link, Modal, Typography, IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { getCandidateDetails } from 'services/jobPipeline';
import styled from 'styled-components';
import { CandidateDetails } from 'types/jobPipeline';
import { Email, LocationOn, BusinessCenter, Payments } from 'styled-icons/material';
import { Linkedin } from 'styled-icons/boxicons-logos';
import { Download, Lock } from 'styled-icons/boxicons-regular';
import { useJobPipeline } from '../contexts/JobPipelineContext';
import { formatLabel } from 'utilities/utils';
const ResumeModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Allow scroll if content exceeds height */
`;

const ResumeContainer = styled(Box)`
  position: relative;
  width: 595px;
  background: #ffffff;
  padding: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px; /* Space between pages when content exceeds one page */
  overflow-y: auto; /* Ensures scrolling within the container */
  max-height: 90vh; /* Sets max height to handle large content gracefully */
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const HeaderLeft = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HeaderRight = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: end;
`;

const DownloadButton = styled(IconButton)`
  background: #325cd6 !important;
  color: white !important;
`;

const Title = styled(Typography)`
  font-weight: 500 !important;
  font-size: 30px !important;
  color: #171717 !important;
  line-height: 1.2 !important;
`;

const SubTitle = styled(Typography)`
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #555555;
`;

const InfoBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0;
`;

const Infotext = styled(Typography)`
display: flex !important;
align-items: center !important;
font-size: 12px !important;
gap: 5px !important;
color: #171717 !important;
`;

const IconText = styled(Typography)`
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  gap: 5px !important;
  color: #007bff !important;
  text-decoration: underline; 
`;

const SectionTitle = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  color: #325cd6;
  margin: 16px 0 8px;
  text-transform: uppercase;
`;

const DetailText = styled(Typography)`
  font-weight: 400 !important;
  font-size: 11px !important;
  color: #6d6d6d !important;
  line-height: 1.4 !important;
  margin: 8px 0 !important;
`;

const ContactSection = styled(Box)`
  display: flex;
  gap: 24px;
  align-items: flex-start;
  margin-top: 10px;
`;

const VerticalDivider = styled(Box)`
  width: 1px;
  height: 16px; 
  background-color: #bfd0ff; 
  margin: 0 8px; 
`;

const ContactInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ExperienceItem = styled(Box)`
  margin-bottom: 12px;
`;

const ExperienceTitle = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  color: #171717;
`;

const Divider = styled(Box)`
  border-top: 1px solid #bfd0ff;
  margin: 12px 0;
`;

const EqualGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const SkillsColumn = styled(Box)`
  flex: 1;
`;

const SkillsTitle = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  color: #333333;
  margin-bottom: 4px;
`;

const CandidateResumeComponent = ({
    open,
    handleClose,
    candidateId,
    expertId,
}: {
    open: boolean;
    handleClose: () => void;
    candidateId: string;
    expertId: string;
}) => {
    const { currentJob } = useJobPipeline();
    const [candidateDetails, setCandidateDetails] = useState<CandidateDetails | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const containerRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const fetchCandidateDetails = async () => {
            try {
                const details = await getCandidateDetails(expertId, candidateId);
                setCandidateDetails(details);
            } catch (error) {
                console.error('Failed to fetch candidate details:', error);
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            setLoading(true);
            fetchCandidateDetails();
        }
    }, [open, expertId, candidateId]);


    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;
        }
    }, [candidateDetails]);

    if (loading) {
        return (
            <ResumeModal open={open} onClose={handleClose}>
                <CircularProgress />
            </ResumeModal>
        );
    }

    if (!candidateDetails) {
        return (
            <ResumeModal open={open} onClose={handleClose}>
                <Typography>Failed to load resume.</Typography>
            </ResumeModal>
        );
    }


    const formattedSalary = candidateDetails.expectedSalary > 0
        ? `${candidateDetails.currency} ${candidateDetails.expectedSalary}`
        : 'Salary not available';

    const jobTypeFormatted = formatLabel(candidateDetails.jobType) || 'Job type not available';

    return (
        <ResumeModal open={open} onClose={handleClose}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <ResumeContainer ref={containerRef}>
                    <HeaderRight>
                        <DownloadButton>
                            <Download size={20} />
                        </DownloadButton>
                        <DownloadButton>
                            <Lock size={20} />
                        </DownloadButton>
                    </HeaderRight>
                    <ContactSection>
                        <HeaderLeft>
                            <Title>{candidateDetails.name}</Title>
                            <SubTitle>{currentJob?.jobTitle || ""}</SubTitle>
                            <InfoBox>
                                <Infotext>
                                    <Payments size={16} /> {formattedSalary}
                                </Infotext>
                                <VerticalDivider />
                                <Infotext>
                                    <BusinessCenter size={16} /> {jobTypeFormatted}
                                </Infotext>
                                <VerticalDivider />
                                {candidateDetails.location || candidateDetails.country ? (
                                    <Link
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(candidateDetails.location || candidateDetails.country)}`}
                                        target="_blank"
                                        underline="none"
                                        color="inherit"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        <LocationOn size={16} />
                                        {candidateDetails.location || candidateDetails.country}
                                    </Link>
                                ) : (
                                    <Infotext>
                                        <LocationOn size={16} /> Location not available
                                    </Infotext>
                                )}
                            </InfoBox>
                        </HeaderLeft>

                        <VerticalDivider />
                        <ContactInfo>
                            <IconText>
                                <Link href={`mailto:${candidateDetails.email}`} target="_blank" underline="none" color="inherit">
                                    <Email size={16} />
                                    {candidateDetails.email}
                                </Link>
                            </IconText>

                            <IconText>
                                <Link href={candidateDetails.linkedInUrl ? candidateDetails.linkedInUrl : '#'} target="_blank" underline="none" color="inherit">
                                    <Linkedin size={16} />
                                    {candidateDetails.linkedInUrl ? 'LinkedIn Profile' : 'LinkedIn URL not available'}
                                </Link>
                            </IconText>

                            <IconText>
                                {candidateDetails.location || candidateDetails.country ? (
                                    <Link
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(candidateDetails.location || candidateDetails.country)}`}
                                        target="_blank"
                                        underline="none"
                                        color="inherit"
                                    >
                                        <LocationOn size={16} />
                                        {candidateDetails.location || candidateDetails.country}
                                    </Link>
                                ) : (
                                    <span>
                                        <LocationOn size={16} />
                                        Location not available
                                    </span>
                                )}
                            </IconText>
                        </ContactInfo>
                    </ContactSection>

                    <DetailText>
                        {candidateDetails.careerProgress?.[0]?.progressDescription ||
                            'No career progress description available.'}
                    </DetailText>

                    <Divider />

                    <SectionTitle>Experience</SectionTitle>
                    {candidateDetails.candidateExperience && candidateDetails.candidateExperience.length > 0 ? (
                        candidateDetails.candidateExperience.map((exp, index) => (
                            <ExperienceItem key={index}>
                                <ExperienceTitle>
                                    {exp.company || 'Company not available'}, {exp.title || 'Title not available'}
                                </ExperienceTitle>
                                <DetailText>{exp.duration || 'Duration not available'}</DetailText>
                                <DetailText>{exp.description || 'Description not available'}</DetailText>
                            </ExperienceItem>
                        ))
                    ) : (
                        <DetailText>No experience details available.</DetailText>
                    )}

                    <EqualGrid>
                        <Box>
                            <SectionTitle>Education</SectionTitle>
                            {candidateDetails.educationDetails && candidateDetails.educationDetails.length > 0 ? (
                                candidateDetails.educationDetails.map((edu, index) => (
                                    <Box key={index}>
                                        <ExperienceTitle>
                                            {edu.degree}, {edu.university}
                                        </ExperienceTitle>
                                        <DetailText>{edu.passingYear || 'Year not available'}</DetailText>
                                    </Box>
                                ))
                            ) : (
                                <DetailText>No education details available.</DetailText>
                            )}
                        </Box>

                        <Box>
                            <SectionTitle>Technical Skills</SectionTitle>
                            {candidateDetails.skills && candidateDetails.skills?.length > 0 ? (
                                candidateDetails.skills?.map((skill, index) => (
                                    <SkillsColumn key={index}>
                                        <SkillsTitle>{skill.skillName}</SkillsTitle>
                                        <DetailText>Score: {skill.score}/{skill.maxScore}</DetailText>
                                    </SkillsColumn>
                                ))
                            ) : (
                                <DetailText>No technical skills available.</DetailText>
                            )}
                        </Box>
                    </EqualGrid>

                    <SectionTitle>Certifications</SectionTitle>
                    <DetailText>Certified ScrumMaster (CSM), AWS Certified Solutions Architect</DetailText>
                </ResumeContainer>
            </Box>
        </ResumeModal>
    );
};

export default CandidateResumeComponent;