import { Avatar, Box, Button, CircularProgress, MenuItem, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Star } from '@styled-icons/fa-solid/Star';
import { currencyList } from 'containers/HiringManager/Jobs.util';
import moment from 'moment';
import React, { useState } from 'react';
import { submitEvaluations } from 'services/jobPipeline';
import styled from 'styled-components';
import { IntentData } from 'types/jobPipeline';

const MotivationButton = styled(ToggleButton) <{ selected: boolean }>`
  padding: 6px 12px;
  font-size: 12px;
  height: 30px !important;
  margin: 0.5rem 0.5rem 0.5rem 0 !important;
  border-radius: 18px !important;
  border: 1px solid ${({ selected }) => (selected ? '#1976d2 !important' : '#1976d2 !important')};
  background-color: ${({ selected }) => (selected ? '#1976d2 !important' : 'white !important')};
  color: ${({ selected }) => (selected ? '#fff !important' : '#1976d2 !important')};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    background-color: ${({ selected }) => (selected ? '#1e58c7' : '#e3f2fd')};
  }
  .icon {
    margin-right: 4px;
    color: ${({ selected }) => (selected ? '#fff' : '#1976d2')};
  }
`;

const StyledComment = styled(Typography)`
  font-style: italic;
  color: #757575;
`;

interface IntentEvaluationProps {
    expertId: string;
    jobId: string;
    candidateId: string;
    candidateIntentData?: IntentData;
    onSubmitSuccess: () => void;
}

const IntentEvaluationComponent: React.FC<IntentEvaluationProps> = ({
    expertId,
    jobId,
    candidateId,
    candidateIntentData,
    onSubmitSuccess,
}) => {
    const [intentComment, setIntentComment] = useState('');
    const [candidateMotivation, setCandidateMotivation] = useState<string>('HIGHLY_MOTIVATED');
    const [compensation, setCompensation] = useState({
        currency: 'USD',
        hourlyRate: 0,
        yearlyRate: 0,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [intentData] = useState<any>(candidateIntentData);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
            await submitEvaluations({
                expertId,
                jobId,
                candidateId,
                token: '123',
                intentEvaluation: {
                    expertComment: intentComment,
                    candidateMotivation: candidateMotivation || '',
                    expectedCompensation: compensation,
                },
            });
            onSubmitSuccess();
        } catch (error) {
            console.error('Failed to submit intent evaluation:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (intentData) {
        const { expertName, expertComment, candidateMotivation, completedOn, profileImgUrl } = intentData;
        const initials = expertName?.split(' ').map((name: string) => name[0]).join('');

        return (
            <Box display="flex" alignItems="flexStart" mb={2}>
                {profileImgUrl ? (
                    <Avatar src={profileImgUrl} alt={expertName} sx={{ width: 40, height: 40, mr: 2 }} />
                ) : (
                    <Avatar sx={{ bgcolor: '#1976d2', width: 40, height: 40, mr: 2 }}>
                        {initials}
                    </Avatar>
                )}
                <Box>
                    <Typography variant="body1" fontWeight="bold">
                        {expertName} <Typography variant="caption" color="textSecondary">{moment(completedOn).format('MMMM D, YYYY')}</Typography>
                    </Typography>
                    <StyledComment variant="body2">"{expertComment}"</StyledComment>
                    <MotivationButton size="small" selected value={candidateMotivation}>
                        {candidateMotivation === 'HIGHLY_MOTIVATED' && <Star size="12" className="icon" />}
                        {candidateMotivation?.replace('_', ' ')}
                    </MotivationButton>
                </Box>
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant="body2" color="textSecondary" mb={2}>
                Please provide the intent evaluation details below.
            </Typography>
            <TextField
                label="Add Comments, if Any."
                fullWidth
                multiline
                rows={2}
                value={intentComment}
                onChange={(e) => setIntentComment(e.target.value)}
                sx={{ mb: 2 }}
                InputProps={{
                    sx: { height: '80px' },
                }}
            />
            <Typography variant="body2" mb={1}>
                How Motivated is the Candidate towards this Job?
            </Typography>
            <ToggleButtonGroup
                value={candidateMotivation}
                exclusive
                onChange={(_, value) => setCandidateMotivation(value)}
                sx={{ mb: 2 }}
            >
                <MotivationButton size="small" value="HIGHLY_MOTIVATED" selected={candidateMotivation === 'HIGHLY_MOTIVATED'}>
                    <Star size="12" className="icon" />
                    Highly Motivated
                </MotivationButton>
                <MotivationButton size="small" value="SOMEWHAT_MOTIVATED" selected={candidateMotivation === 'SOMEWHAT_MOTIVATED'}>
                    Somewhat Motivated
                </MotivationButton>
                <MotivationButton size="small" value="NOT_MOTIVATED" selected={candidateMotivation === 'NOT_MOTIVATED'}>
                    Not Motivated
                </MotivationButton>
            </ToggleButtonGroup>
            <Typography variant="body2" mb={1}>
                Expected Compensation:
            </Typography>
            <Box display="flex" gap={2} mb={2}>
                <TextField
                    label="Currency"
                    select
                    value={compensation.currency}
                    onChange={(e) => setCompensation({ ...compensation, currency: e.target.value })}
                    sx={{ width: '150px' }}
                    size="small"
                >
                    {currencyList.map((currency) => (
                        <MenuItem key={currency.code} value={currency.code}>
                            {currency.text}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Per Hour"
                    type="number"
                    value={compensation.hourlyRate}
                    onChange={(e) => setCompensation({ ...compensation, hourlyRate: +e.target.value })}
                    sx={{ width: '120px' }}
                    size="small"
                />
                <TextField
                    label="Per Annum"
                    type="number"
                    value={compensation.yearlyRate}
                    onChange={(e) => setCompensation({ ...compensation, yearlyRate: +e.target.value })}
                    sx={{ width: '120px' }}
                    size="small"
                />
            </Box>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
            </Button>
        </Box>
    );
};

export default IntentEvaluationComponent;