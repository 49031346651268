import { CircularProgress, Divider, Menu, MenuItem } from '@mui/material';
import { CalendarOutline, ChartPieOutline } from '@styled-icons/typicons';
import { getJobCandidatesAction } from 'actions/hiringManager/jobs/jobsActions';
import { setSelectedCandidateForBookMeetingProcess } from 'actions/hiringManager/jobs/jobsSlice';
import { CreateCandidateMeetingRequest } from 'containers/Candidate/ICandidate';
import { useJobPipeline } from 'containers/JobPipeline/contexts/JobPipelineContext';
import { ConfirmationModal } from 'pages/B2B/Jobs/ConfirmationModal';
import { CreateCandidateWorkFlow, CreateCandidateWorkflowSteps } from 'pages/B2B/Jobs/CreateCandidateWorkFlow';
import SendCustomReminder from 'pages/B2B/Jobs/SendCustomReminder/SendCustomReminder';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { candidateService } from 'services/candidate';
import { jobsService } from 'services/jobs';
import { BellOutline, EditOutline, TrashOutline } from 'styled-icons/evaicons-outline';
import { Candidate } from 'types/jobPipeline';
import { ChangeCandidateStatusRequest } from 'types/Jobs';
import { buildUrlWithParams } from 'utilities/commonUtils';
import { API_STATUSES, BASE_PORTAL_URL, DASHBOARD_URL_CANDIDATE_APP, DEFAULT_TOKEN, IS_SIMULATION_MODE } from 'utilities/constants';

interface JobCardMenuOptionsProps {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    candidateId: string;
    candidateTrackId: string;
    trackId: string;
    expertId: string;
    candidate: Candidate;
}

const JobCardMenuOptions: React.FC<JobCardMenuOptionsProps> = ({
    anchorEl,
    onClose,
    candidateId,
    candidateTrackId,
    trackId,
    expertId,
    candidate,
}) => {
    const { currentJob, loadJobs } = useJobPipeline();
    const dispatch = useDispatch();
    const [startWorkFlow, setStartWorkFlow] = useState<CreateCandidateWorkflowSteps | undefined>();
    const [showCreateCandidate, setShowCreateCandidate] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSendCustomReminder, setShowSendCustomReminder] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const handleViewPerformance = async () => {
        try {
            if (candidateId && candidateTrackId) {
                const response = await candidateService.getCandidateAuthorizationToken({
                    expertId,
                    token: DEFAULT_TOKEN,
                    candidateId,
                });

                if (response.apiStatus === API_STATUSES.SUCCESS) {
                    const { authorizationToken: token } = response.output;
                    const urlParams = {
                        candidateId,
                        trackid: trackId,
                        candidateTrackId,
                        authorizationToken: token,
                        lpflowtype: 'enroll',
                        isHiringManager: true,
                        [IS_SIMULATION_MODE]: true,
                    };
                    const url = buildUrlWithParams(BASE_PORTAL_URL + DASHBOARD_URL_CANDIDATE_APP, urlParams);
                    window.open(url, '_blank');
                } else {
                    toast.error('Something went wrong');
                }
            }
        } catch (error) {
            console.error('Error viewing performance:', error);
            toast.error('Failed to view performance');
        }
        onClose();
    };

    const handleMeetingBooked = (payload: CreateCandidateMeetingRequest) => {
        if (currentJob?.jobId) {
            candidateService.createMeeting(payload)
                .then(() => {
                    getJobCandidatesAction({ jobId: currentJob.jobId, expertId });
                    setShowCreateCandidate(false);
                    toast.success("Meeting booked successfully!");
                })
                .catch(() => {
                    toast.error("Failed to book the meeting, please try again.");
                });
        }
    };

    const completeMeetingBookingStep = () => {
        dispatch(
            setSelectedCandidateForBookMeetingProcess({
                candidateId,
                candidateTrackId,
                trackId,
                expertId,
            })
        );
        handleCreateCandidate(CreateCandidateWorkflowSteps.SELECT_PRODUCT);
    };

    const handleCreateCandidate = (workflowStep?: CreateCandidateWorkflowSteps) => {
        setStartWorkFlow(workflowStep ?? CreateCandidateWorkflowSteps.CREATE_CANDIDATE);
        setShowCreateCandidate(true);
    };

    const handleSendReminder = () => {
        setShowSendCustomReminder(true);
        onClose();
    };

    const confirmRemoveCandidate = async (reason: string) => {
        setIsLoading(true);
        if (currentJob?.jobId && candidateId) {
            try {
                const payload: ChangeCandidateStatusRequest = {
                    sendEmail: true,
                    expertId,
                    jobId: currentJob?.jobId,
                    candidateId,
                    fastTrack: currentJob.fastTrack,
                    status: 'REJECTED_CANDIDATE',
                    reason: reason || 'Removed by user action',
                };
                await jobsService.changeCandidateStatus(payload);
                toast.success('Candidate removed successfully!');
                await loadJobs(currentJob.jobId);
            } catch (error) {
                console.error('Error removing candidate:', error);
                toast.error('Failed to remove candidate. Please try again.');
            } finally {
                setIsLoading(false);
                setShowConfirmationModal(false);
                onClose();
            }
        }
    };

    const handleRemoveCandidate = () => {
        onClose(); 
        setShowConfirmationModal(true); 
    };

    const handleEditDetails = () => {
        toast.info('Edit details feature coming soon!');
        onClose();
    };

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleViewPerformance}>
                    <ChartPieOutline size={20} style={{ marginRight: '8px' }} />
                    View Detailed Analysis
                </MenuItem>
                <Divider className='m-0' />
                <MenuItem onClick={completeMeetingBookingStep}>
                    <CalendarOutline size={20} style={{ marginRight: '8px' }} />
                    Schedule a Meeting
                </MenuItem>
                <Divider className='m-0' />
                <MenuItem onClick={handleSendReminder}>
                    <BellOutline size={20} style={{ marginRight: '8px' }} />
                    Send a Reminder
                </MenuItem>
                <Divider className='m-0' />
                <MenuItem onClick={handleEditDetails}>
                    <EditOutline size={20} style={{ marginRight: '8px' }} />
                    Edit Candidate Details
                </MenuItem>
                <Divider className='m-0' />
                <MenuItem onClick={handleRemoveCandidate} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={20} style={{ marginRight: '8px' }} /> : <TrashOutline size={20} style={{ marginRight: '8px', color: 'red' }} />}
                    Remove Candidate
                </MenuItem>
            </Menu>

            {showCreateCandidate && (
                <CreateCandidateWorkFlow
                    setShowCreateCandidate={setShowCreateCandidate}
                    jobId={currentJob?.jobId!}
                    trackId={trackId!}
                    startWorkFlow={startWorkFlow ?? CreateCandidateWorkflowSteps.SELECT_PRODUCT}
                    handleMeetingBooked={handleMeetingBooked}
                />
            )}

            {showSendCustomReminder && (
                <SendCustomReminder
                    show={showSendCustomReminder}
                    handleClose={() => setShowSendCustomReminder(false)}
                    expertId={expertId}
                    candidateId={candidateId}
                    jobId={currentJob?.jobId!}
                    incrementReminderCount={() => { }}
                />
            )}

            {showConfirmationModal && (
                <ConfirmationModal
                    show={showConfirmationModal}
                    buttonText="Remove"
                    candidateName={candidate.fullname}
                    showReason={true}
                    onSuccess={confirmRemoveCandidate}
                    onReject={() => setShowConfirmationModal(false)}
                />
            )}
        </>
    );
};

export default JobCardMenuOptions;