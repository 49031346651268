import { Box, Button, Divider, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Person } from 'styled-icons/material';

const Tag = styled.span<{ background?: string }>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${({ background }) => background || '#81c784'};
  color: #2e7d32;
  font-size: 12px;
  font-weight: bold;
  margin: 8px;
`;

const ViewResumeButton = styled(Button)`
  margin-top: 8px;
  background-color: #f0f4ff !important;
  color: #325cd6 !important;
  text-transform: none;
  &:hover {
    background-color: #e6ecff !important;
  }
`;

const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap; 
`;

interface AIResumeEvaluationProps {
    aiResumeEvaluation: {
        careerProgressionDetails: string;
        careerProgress: string;
        universityScore: number;
        jobMatchScore: number | null;
        resumeUrl: string;
    };
}

const AIResumeEvaluationComponent: React.FC<AIResumeEvaluationProps> = ({
    aiResumeEvaluation,
}) => {
    const { careerProgress, universityScore, jobMatchScore, careerProgressionDetails, resumeUrl } = aiResumeEvaluation;

    const handleViewResume = () => {
        window.open(resumeUrl, '_blank');
    };

    return (
        <Box>
            <HeaderContainer mb={1}>
                <Tag background="#e0f2f1">{careerProgress}</Tag>
                <Tag background="#e0f7fa">University Score: {universityScore}</Tag>
                {jobMatchScore !== null && <Tag background="#e8f5e9">Job Match Score: {jobMatchScore}%</Tag>}
            </HeaderContainer>
            <Typography variant="body2" color="textSecondary" mb={2}>
                {careerProgressionDetails}
            </Typography>
            <ViewResumeButton
                variant="contained"
                onClick={handleViewResume}
                startIcon={<Person color="#325CD6" size="24" />}
            >
                View Resume
            </ViewResumeButton>
            <Divider sx={{ my: 2 }} />
        </Box>
    );
};

export default AIResumeEvaluationComponent;