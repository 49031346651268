import React from 'react';
import { Box, Typography, Avatar, IconButton, Divider } from '@mui/material';
import styled from 'styled-components';
import { useJobPipeline } from '../contexts/JobPipelineContext';
import { Edit, Bell, FileText, Trash } from 'styled-icons/feather';
import { formatLabel } from 'utilities/utils';

const ActionIcon = styled(IconButton)`
  color: white;
  margin-right: 8px;
  padding: 6px;
  border-radius: 50%;
`;

const PrimaryIcon = styled(ActionIcon)`
  color: #FFF !important;
  margin: 0 5px !important;
  background-color: #325cd6 !important;
  &:hover {
    background-color: #1a3f98 !important;
  }
`;

const DangerIcon = styled(ActionIcon)`
  color: #FFF !important;
  margin: 0 5px !important;
  background-color: #d32f2f !important;
  &:hover {
    background-color: #9a0007 !important;
  }
`;

const Tag = styled.span<{ background: string }>`
  padding: 2px 8px;
  border-radius: 12px;
  background-color: ${props => props.background};  
  font-size: 10px;
  font-weight: 600;
  color: black;
  opacity: 0.9;
  margin-left: 8px;
`;

const InfoBox = styled(Box)`
  display: flex;
  gap: 16px 0;
`;

const CandidateProfileHeader: React.FC = () => {
    const { activeCandidateProfile, currentJob } = useJobPipeline();

    if (!activeCandidateProfile) {
        return null;
    }

    return (
        <Box display="flex" justifyContent="space-between" py={2}>
            <Box display="flex" alignItems="flex-start">
                <Avatar
                    alt={activeCandidateProfile.fullname}
                    src={activeCandidateProfile.image || ''}
                    sx={{ width: 56, height: 56 }}
                >
                    {!activeCandidateProfile.image && activeCandidateProfile.fullname[0]}
                </Avatar>
                <Box ml={2}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" fontWeight="600">
                            {activeCandidateProfile.fullname}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            {activeCandidateProfile.candidateStates.map(state => (
                                <Tag key={state.label} background={state.label === "Intent" ? "#e0e0e0" : "#f57c00"}>
                                    {formatLabel(state.label)}
                                </Tag>
                            ))}
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <InfoBox>
                            <Box ml={0} mx={2}>
                                <Typography variant="subtitle2" color="textSecondary">
                                    {currentJob?.jobTitle || 'No Job Title'}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {activeCandidateProfile.candidateResumeDetails?.company || "N/A"} | {activeCandidateProfile.candidateResumeDetails?.yearsOfExperience || 'N/A'}
                                </Typography>
                            </Box>
                            <Divider orientation='vertical' />

                            <Box mx={2}>
                                <Typography variant="caption" color="textSecondary">Owner:</Typography>
                                <Typography variant="body2">{currentJob?.companyInfo.companyName || 'N/A'}</Typography>
                            </Box>
                            <Divider orientation='vertical' />

                            <Box mx={2}>
                                <Typography variant="caption" color="textSecondary">Agency:</Typography>
                                <Typography variant="body2" color="#325cd6">{currentJob?.companyProvide || 'N/A'}</Typography>
                            </Box>
                            <Divider orientation='vertical' />

                            <Box mx={2}>
                                <Typography variant="caption" color="textSecondary">Source:</Typography>
                                <Typography variant="body2">{formatLabel(activeCandidateProfile.candidateJobMappingSource) || 'N/A'}</Typography>
                            </Box>
                        </InfoBox>
                    </Box>
                    <Box display="flex" alignItems="center" mt={1}>
                        <PrimaryIcon>
                            <FileText size="16" />
                        </PrimaryIcon>
                        <PrimaryIcon>
                            <Bell size="16" />
                        </PrimaryIcon>
                        <PrimaryIcon>
                            <Edit size="16" />
                        </PrimaryIcon>
                        <DangerIcon>
                            <Trash size="16" />
                        </DangerIcon>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CandidateProfileHeader;