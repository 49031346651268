import axios from "axios";
import {
  CandidateDetails,
  CandidatesResponse,
  ClientEvaluation,
  IGetJobDetails,
  ISelectionEvaluation,
} from "types/jobPipeline";
import { post } from "utilities/apiUrlpp";
import { DEFAULT_TOKEN } from "utilities/constants";

export const API_URL_PREFIX = "evaluationPlatform/expert";

export interface CandidateEvaluationSubmission {
  expertId: string;
  jobId: string;
  candidateId: string;
  token: string;
  intentEvaluation?: {
    expertComment: string;
    candidateMotivation: string;
    expectedCompensation: {
      currency: string;
      hourlyRate?: number;
      yearlyRate?: number;
    };
  };
  clientEvaluation?: ClientEvaluation[];
  selectionStatus?: ISelectionEvaluation;
}

export const submitEvaluations = async (
  payload: CandidateEvaluationSubmission
): Promise<void> => {
  try {
    const response = await post(`${API_URL_PREFIX}/submitEvaluations`, {
      ...payload,
    });
    console.log("Evaluations submitted successfully", response);
  } catch (error) {
    console.error("Failed to submit evaluations:", error);
    throw error;
  }
};

// Existing functions
export const fetchJobsData = async (payload: IGetJobDetails) => {
  const jobDetails = await post(`${API_URL_PREFIX}/getJobDetails`, {
    ...payload,
    token: DEFAULT_TOKEN,
  });
  return jobDetails;
};

export const fetchCandidatesForJob = async (
  payload: IGetJobDetails
): Promise<CandidatesResponse> => {
  try {
    const response = await post(`${API_URL_PREFIX}/V1/getJobCandidates`, {
      ...payload,
      token: DEFAULT_TOKEN,
    });
    console.log(response.output);
    return response.output;
  } catch (error) {
    console.error(
      `Failed to fetch candidates for job ${payload.jobId}:`,
      error
    );
    throw error;
  }
};

export const updateCandidateStatus = async (
  candidateId: string,
  jobId: string,
  expertId: string,
  status: string,
  afterCandidateId: string | null,
  beforeCandidateId: string | null,
  reason = "State changed by expert",
  fastTrack = true,
  sendEmail = true,
): Promise<void> => {
  try {
    const response = await post(`${API_URL_PREFIX}/changeCandidateStatus`, {
      candidateId,
      jobId,
      expertId,
      status,
      reason,
      fastTrack,
      sendEmail,
      afterCandidateId,
      beforeCandidateId,
      token: DEFAULT_TOKEN,
    });
    console.log(`Candidate ${candidateId} status updated to ${status}`);
  } catch (error) {
    console.error(
      `Failed to update status for candidate ${candidateId}:`,
      error
    );
    throw error;
  }
};

export const getCandidateEvaluationStatus = async (
  expertId: string,
  jobId: string,
  candidateId: string
): Promise<any> => {
  try {
    const response = await post(
      `${API_URL_PREFIX}/getCandidateEvaluationStatus`,
      {
        expertId,
        jobId,
        candidateId,
        token: DEFAULT_TOKEN,
      }
    );
    return response.output;
  } catch (error) {
    console.error(
      `Failed to fetch evaluation status for candidate ${candidateId}:`,
      error
    );
    throw error;
  }
};

export const getCandidateDetails = async (
  expertId: string,
  candidateId: string
): Promise<CandidateDetails | null> => {
  try {
    const response = await post(`${API_URL_PREFIX}/getCandidateDetails`, {
      token: DEFAULT_TOKEN,
      expertId,
      candidateId,
    });
    return response.output.candidates;
  } catch (error) {
    console.error("Failed to fetch candidate details:", error);
    return null;
  }
};

const axiosInstance = axios.create({
  baseURL: "/api",
  timeout: 1000,
});

export const getJobs = async () => {
  try {
    const response = await axiosInstance.get("/jobs");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch jobs:", error);
    throw error;
  }
};

export const getCandidatesForJob = async (jobId: string) => {
  try {
    const response = await axiosInstance.get(`/jobs/${jobId}/candidates`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch candidates for job ${jobId}:`, error);
    throw error;
  }
};

export const toggleFavoriteCandidate = async (
  expertId: string,
  jobId: string,
  candidateId: string,
  favorite: boolean
): Promise<void> => {
  try {
    const response = await post(`${API_URL_PREFIX}/updateJobFavoriteCandidate`, {
      token: DEFAULT_TOKEN,
      expertId,
      jobId,
      candidateId,
      favorite,
    });
    console.log(`Candidate ${candidateId} favorite status set to ${favorite}`);
  } catch (error) {
    console.error(`Failed to update favorite status for candidate ${candidateId}:`, error);
    throw error;
  }
};

export const updateJobCandidateRanking = async (
  candidateId: string,
  jobId: string,
  expertId: string,
  beforeCandidateId: string | null,
  afterCandidateId: string | null,
  currentRank: number
): Promise<void> => {
  try {
    const response = await post(`${API_URL_PREFIX}/updateJobCandidateRanking`, {
      candidateId,
      jobId,
      expertId,
      beforeCandidateId,
      afterCandidateId,
      currentRank,
      token: DEFAULT_TOKEN,
    });
    console.log(`Candidate ${candidateId} rank updated successfully`);
  } catch (error) {
    console.error(`Failed to update rank for candidate ${candidateId}:`, error);
    throw error;
  }
};

export const getResumeParsingStatus = async (
  expertId: string,
  candidateId: string
): Promise<{ parsingCompleted: boolean }> => {
  try {
    const response = await post(`${API_URL_PREFIX}/getResumeParsingStatus`, {
      token: DEFAULT_TOKEN,
      expertId,
      candidateId,
    });
    return response.output;
  } catch (error) {
    console.error(`Failed to fetch resume parsing status for candidate ${candidateId}:`, error);
    throw error;
  }
};