import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import Loader from 'react-spinners/ClipLoader';
import { toast } from 'react-toastify';
import { fetchCandidatesForJob, fetchJobsData, updateCandidateStatus, updateJobCandidateRanking } from 'services/jobPipeline';
import { Candidate, Job } from 'types/jobPipeline';

interface JobPipelineContextProps {
    currentJob: Job | null;
    fetchJobById: (jobId: string) => Promise<void>;
    currentJobIndex: number;
    activeJobIds: string[];
    closedJobIds: string[];
    setActiveJobIds: (jobIds: string[]) => void;
    setClosedJobIds: (jobIds: string[]) => void;
    setCurrentJobIndex: (index: number) => void;
    loadJobs: (jobId: string) => Promise<void>
    talentPoolCandidates: Candidate[];
    coachClientEvaluationsCandidates: Candidate[];
    selectedCandidates: Candidate[];
    rejectedCandidates: Candidate[];
    activeTab: string;
    activeCandidateProfile?: Candidate;
    handleTabChange: (tab: string) => void;
    handleActiveCandidateProfile: (candidate?: Candidate) => void;
    handlePrevJob: () => void;
    handleNextJob: () => void;
    handleFavoriteToggle: (id: string, favorite: boolean) => void;
    handleMenuAction: (action: string, id: string) => void;
    handleOnDragEnd: (result: DropResult) => void;
}

const JobPipelineContext = createContext<JobPipelineContextProps | undefined>(undefined);

export const useJobPipeline = () => {
    const context = useContext(JobPipelineContext);
    if (!context) {
        throw new Error('useJobPipeline must be used within a JobPipelineProvider');
    }
    return context;
};

export const JobPipelineProvider: React.FC<{ children: React.ReactNode; jobId: string; expertId: string }> = ({ children, jobId, expertId }) => {
    const [currentJob, setCurrentJob] = useState<Job | null>(null);
    const [activeJobIds, setActiveJobIds] = useState<string[]>([]);
    const [closedJobIds, setClosedJobIds] = useState<string[]>([]);
    const [currentJobIndex, setCurrentJobIndex] = useState<number>(0);
    const [talentPoolCandidates, setTalentPoolCandidates] = useState<Candidate[]>([]);
    const [coachClientEvaluationsCandidates, setCoachClientEvaluationsCandidates] = useState<Candidate[]>([]);
    const [selectedCandidates, setSelectedCandidates] = useState<Candidate[]>([]);
    const [rejectedCandidates, setRejectedCandidates] = useState<Candidate[]>([]);
    const [activeTab, setActiveTab] = useState('Active');
    const [activeCandidateProfile, setActiveCandidateProfile] = useState<Candidate>();
    const [loading, setLoading] = useState(false);

    const loadCandidates = useCallback(async (jobId: string) => {
        try {
            const {
                talentPoolCandidates,
                coachAndClientEvaluationsCandidates,
                finalizeCandidates,
                rejectedCandidates,
            } = await fetchCandidatesForJob({ jobId, expertId });

            const sortByFavoriteAndOrder = (candidates: Candidate[]) => {
                return candidates.sort((a, b) => {
                    if (a.favorite && !b.favorite) return -1;
                    if (!a.favorite && b.favorite) return 1;
                    return (a.order || 0) - (b.order || 0);
                });
            };

            setTalentPoolCandidates(sortByFavoriteAndOrder(talentPoolCandidates));
            setCoachClientEvaluationsCandidates(sortByFavoriteAndOrder(coachAndClientEvaluationsCandidates));
            setSelectedCandidates(sortByFavoriteAndOrder(finalizeCandidates));
            setRejectedCandidates(sortByFavoriteAndOrder(rejectedCandidates));
        } catch (error) {
            console.error('Failed to load candidates:', error);
            toast.error('Failed to load candidates', { position: 'top-right', autoClose: 5000 });
        }
    }, [expertId]);

    const loadJobs = useCallback(async (jobId: string) => {
        if (expertId && jobId) {
            setLoading(true);
            try {
                const jobData = await fetchJobsData({ expertId, jobId });
                setCurrentJob(jobData.output);
                await loadCandidates(jobId);
            } catch (error) {
                console.error('Failed to load job data:', error);
                toast.error('Failed to load job data', { position: 'top-right', autoClose: 5000 });
            } finally {
                setLoading(false);
            }
        }
    }, [expertId, loadCandidates]);

    useEffect(() => {
        if (jobId) {
            loadJobs(jobId);
        }
    }, [expertId, jobId, loadJobs]);

    const handleTabChange = useCallback((tab: string) => {
        setActiveTab(tab);

        const jobIds = tab === 'Active' ? activeJobIds : closedJobIds;
        if (jobIds.length > 0) {
            const firstJobId = jobIds[0];
            setCurrentJobIndex(0);
            loadJobs(firstJobId);
        }
    }, [activeJobIds, closedJobIds, loadJobs]);


    const handleActiveCandidateProfile = (candidate?: Candidate) => {
        setActiveCandidateProfile(candidate);
    };
    const fetchJobById = useCallback(async (jobId: string) => {
        setLoading(true);
        try {
            const jobData = await fetchJobsData({ expertId, jobId });
            setCurrentJob(jobData.output);
            await loadCandidates(jobId);
            updateQueryParam(jobId);
        } catch (error) {
            console.error('Failed to load job data:', error);
            toast.error('Failed to load job data', { position: 'top-right', autoClose: 5000 });
        } finally {
            setLoading(false);
        }
    }, [expertId, loadCandidates]);

    const handlePrevJob = useCallback(async () => {
        if (currentJobIndex > 0) {
            const newIndex = currentJobIndex - 1;
            const newJobId = activeTab === 'Active' ? activeJobIds[newIndex] : closedJobIds[newIndex];
            await fetchJobById(newJobId);
            setCurrentJobIndex(newIndex);
        }
    }, [currentJobIndex, activeTab, activeJobIds, closedJobIds, fetchJobById]);

    const handleNextJob = useCallback(async () => {
        const jobIds = activeTab === 'Active' ? activeJobIds : closedJobIds;
        if (currentJobIndex < jobIds.length - 1) {
            const newIndex = currentJobIndex + 1;
            const newJobId = jobIds[newIndex];
            await fetchJobById(newJobId);
            setCurrentJobIndex(newIndex);
        }
    }, [currentJobIndex, activeTab, activeJobIds, closedJobIds, fetchJobById]);

    const updateQueryParam = (jobId: string) => {
        const url = new URL(window.location.href);
        url.searchParams.set('jobId', jobId);
        window.history.pushState({}, '', url.toString());
    };

    const handleFavoriteToggle = (id: string) => {
        // Implement favorite toggle logic
    };

    const handleMenuAction = (action: string, id: string) => {
        // Implement menu action logic
    };

    const getCandidatesBySectionId = (sectionId: string): Candidate[] => {
        switch (sectionId) {
            case 'talent_pool':
                return [...talentPoolCandidates];
            case 'interview_request':
                return [...coachClientEvaluationsCandidates];
            case 'finalized_candidate':
                return [...selectedCandidates];
            case 'rejected_candidates':
                return [...rejectedCandidates];
            default:
                return [];
        }
    };

    const updateCandidatesState = (sectionId: string, updatedCandidates: Candidate[]) => {
        switch (sectionId) {
            case 'talent_pool':
                setTalentPoolCandidates(updatedCandidates);
                break;
            case 'interview_request':
                setCoachClientEvaluationsCandidates(updatedCandidates);
                break;
            case 'finalized_candidate':
                setSelectedCandidates(updatedCandidates);
                break;
            case 'rejected_candidates':
                setRejectedCandidates(updatedCandidates);
                break;
            default:
                break;
        }
    };

    const handleOnDragEnd = useCallback(async (result: DropResult) => {
        if (!result.destination) return;
    
        const { source, destination } = result;
    
        const sourceCandidates = getCandidatesBySectionId(source.droppableId);
        const destCandidates = getCandidatesBySectionId(destination.droppableId);
    
        if (!Array.isArray(sourceCandidates) || !Array.isArray(destCandidates)) {
            console.error('Candidates could not be retrieved for one or both sections');
            return;
        }
    
        const movedCandidate = sourceCandidates[source.index];
        if (!movedCandidate) {
            console.error('No candidate found to move');
            return;
        }
    
        const isDraggingFavorite = movedCandidate.favorite;
        const isTargetFavorite = destCandidates[destination.index]?.favorite;
    
        if (isDraggingFavorite && !isTargetFavorite || !isDraggingFavorite && isTargetFavorite && source.droppableId === destination.droppableId) {
            toast.error('Cannot move favorite candidates into non-favorite positions or vice versa', { position: 'top-right', autoClose: 5000 });
            return;
        }
    
        let beforeCandidateId = null;
        let afterCandidateId = null;
        if (destination.index > source.index) {
            beforeCandidateId = destCandidates[destination.index + 1]?.candidateId;
            afterCandidateId = destCandidates[destination.index]?.candidateId;
        } else {
            beforeCandidateId = destCandidates[destination.index]?.candidateId;
            afterCandidateId = destCandidates[destination.index - 1]?.candidateId;
        }
    
        if (source.droppableId === destination.droppableId) {
            const updatedCandidates = [...sourceCandidates];
            updatedCandidates.splice(source.index, 1); 
            updatedCandidates.splice(destination.index, 0, movedCandidate); 
    
            updateCandidatesState(source.droppableId, updatedCandidates);
    
            await updateJobCandidateRanking(
                movedCandidate.candidateId,
                jobId,
                expertId,
                beforeCandidateId,
                afterCandidateId,
                destination.index
            );
        } else {
            const updatedSourceCandidates = [...sourceCandidates];
            const updatedDestCandidates = [...destCandidates];
    
            updatedSourceCandidates.splice(source.index, 1); // Remove from source
            updatedDestCandidates.splice(destination.index, 0, movedCandidate); // Add to destination
    
            updateCandidatesState(source.droppableId, updatedSourceCandidates);
            updateCandidatesState(destination.droppableId, updatedDestCandidates);
    
            await updateCandidateStatus(
                movedCandidate.candidateId,
                jobId,
                expertId,
                destination.droppableId.toUpperCase(),
                afterCandidateId,
                beforeCandidateId
            );
        }
    }, [jobId, expertId, getCandidatesBySectionId]);

    return (
        <JobPipelineContext.Provider
            value={{
                currentJob,
                activeJobIds,
                setActiveJobIds,
                closedJobIds,
                fetchJobById,
                setClosedJobIds,
                setCurrentJobIndex,
                loadJobs,
                currentJobIndex,
                talentPoolCandidates,
                coachClientEvaluationsCandidates,
                selectedCandidates,
                rejectedCandidates,
                activeTab,
                activeCandidateProfile,
                handleTabChange,
                handlePrevJob,
                handleNextJob,
                handleFavoriteToggle,
                handleMenuAction,
                handleOnDragEnd,
                handleActiveCandidateProfile,
            }}
        >
            {loading && (
                <div className='d-flex align-items-center'>
                    <Loader loading={loading} size={20} color={'blue'} />
                    <span className='ml-3'>loading  ...</span>
                </div>
            )}
            {children}
        </JobPipelineContext.Provider>
    );
};